@use "@ingka/variables/style.scss" as *;
@use "styles/_spacing.scss" as *;

.new-hires {
    &__container {
        display: flex;
        justify-content: space-between;
    }

    &__header {
        align-items: center;
        display: flex;
    }

    &__week-switch-btn {
        padding: 0 $spacing-xxs;
    }

    &-costCenter {
        text-transform: capitalize;
    }
}

.table {
    &__loading {
        display: flex;
        height: $space-1000;
        opacity: 0.1;
        transition: opacity 0.75s;
    }

    &__header {
        width: $space-750 * 2;
    }
}
